import { ECaseChannel } from '../enums/case-channel.enum';
import { ECaseLanguage } from '../enums/case-language.enum';
import { ECaseStatus, ECaseSubStatus } from '../enums/case-status.enum';
import { ECaseVehicleType } from '../enums/case-vehicle-type.enum';
import { ICaseActivityLog } from '../interfaces/case-activity-log.interface';
import { ICaseAdditionalAddons } from '../interfaces/case-additional-addons.interface';
import { ICaseAssignee } from '../interfaces/case-assignee.interface';
import { ICaseAttachment } from '../interfaces/case-attachment.interface';
import { ICaseBilling } from '../interfaces/case-billing.interface';
import { ICaseCampaign } from '../interfaces/case-campaign.interface';
import { ICaseCarCondition } from '../interfaces/case-car-condition.interface';
import { ICaseCustomer } from '../interfaces/case-customer.interface';
import { ICaseEvent } from '../interfaces/case-event.interface';
import { ICaseIssue } from '../interfaces/case-issue.interface';
import { ICaseNotification } from '../interfaces/case-notification.interface';
import { ICasePoliceStation } from '../interfaces/case-police-station.interface';
import { ICaseProvider } from '../interfaces/case-provider.interface';
import { ICaseService } from '../interfaces/case-service.interface';
import { ICaseSmsNotify } from '../interfaces/case-sms-notify.interface';
import { ICaseTowing } from '../interfaces/case-towing.interface';
import { ICaseTracking } from '../interfaces/case-tracking.interface';
import { ICaseUser } from '../interfaces/case-user.interface';
import { ICaseWorkshop } from '../interfaces/case-workshop.interface';
import { ICase } from '../interfaces/case.interface';
import { CaseActivityLog } from './case-activity-log.model';
import { CaseAssignee } from './case-assignee.model';
import { CaseAttachment } from './case-attachment.model';
import { CaseCarCondition } from './case-car-condition.model';
import { CaseCustomer } from './case-customer.model';
import { CaseEvent } from './case-event.model';
import { CaseService } from './case-service.model';
import { CaseTowing } from './case-towing.model';
import { CaseTracking } from './case-tracking.model';
import { CaseWorkshop } from './case-workshop.model';

export class Case implements ICase {
  activityLog: ICaseActivityLog[];
  additionalAddons: ICaseAdditionalAddons[];
  addOnService: string[];
  altPhoneNo: string[];
  amount: number;
  assignee: ICaseAssignee;
  assignmentId: string;
  attachment: ICaseAttachment[];
  billing: ICaseBilling;
  campaign: ICaseCampaign;
  carCondition: ICaseCarCondition;
  channel: ECaseChannel;
  createdAt: Date;
  customer: ICaseCustomer;
  debugErrorCount: number;
  description: string;
  distance: number;
  driverDistance: number;
  entity: string;
  event: ICaseEvent[];
  geoLocation: string;
  id?: string;
  _id?: string;
  issue?: ICaseIssue;
  isTrackingMapShow: boolean;
  language: ECaseLanguage;
  location: string;
  locationRemark: string;
  destination: any;
  name: string;
  email: string;
  notifications: ICaseNotification[];
  personInCharge: string;
  phoneNo: string;
  policeStation: ICasePoliceStation;
  policyCoverage: string;
  provider: ICaseProvider;
  services: ICaseService[];
  smsNotify: ICaseSmsNotify;
  status: ECaseStatus;
  subStatus: ECaseSubStatus;
  ticketId: string;
  towing: ICaseTowing;
  tracking: ICaseTracking;
  users: ICaseUser[];
  vehicleType: ECaseVehicleType;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleDetail?: any;
  vehicleNo: string;
  vehicleYear: number;
  workshop: ICaseWorkshop;
  accidentDate: Date;
  rating?: any;
  automationLog: any;
  supportingDocument: any;
  photoReuploadReason?: string;
  isReuploadPhoto?: boolean;
  jobs: any[];

  constructor(caseData?: Case) {
    if (caseData?.id) {
      this.id = caseData.id;
    } else {
      this.id = caseData?._id;
    }

    this.vehicleNo = caseData?.vehicleNo?.toUpperCase() || null;
    this.description = caseData?.description || null;
    this.location = caseData?.location || null;
    this.workshop = new CaseWorkshop(caseData?.workshop);
    this.status = caseData?.status || null;
    this.subStatus = caseData?.subStatus || null;
    this.provider = caseData?.provider || null;
    this.customer = new CaseCustomer(caseData?.customer);
    //(this.name = caseData?.name || null), (this.nonRegisteredCustomer = new CaseNonRegisteredCustomer(caseData?.nonRegisteredCustomer));
    this.name = caseData?.name;
    this.email = caseData?.email;
    this.assignee = new CaseAssignee(caseData?.assignee);
    this.event = caseData?.event && caseData?.event?.length > 0 ? caseData?.event.map((event) => new CaseEvent(event)) : [];
    this.towing = new CaseTowing(caseData?.towing);
    this.vehicleType = caseData?.vehicleType || null;
    this.vehicleBrand = caseData?.vehicleBrand || null;
    this.vehicleDetail = caseData?.vehicleDetail || null;
    this.vehicleModel = caseData?.vehicleModel || null;
    this.vehicleYear = caseData?.vehicleYear || null;
    this.ticketId = caseData?.ticketId || null;
    this.assignmentId = caseData?.assignmentId || null;
    this.geoLocation = caseData?.geoLocation || null;
    this.addOnService = caseData?.addOnService && caseData?.addOnService?.length > 0 ? caseData?.addOnService : [];
    this.entity = caseData?.entity || null;
    this.policyCoverage = caseData?.policyCoverage || null;
    this.channel = caseData?.channel || null;
    this.tracking = new CaseTracking(caseData?.tracking);
    this.amount = caseData.amount ?? 0;
    this.language = caseData?.language || ECaseLanguage.ENGLISH;
    this.personInCharge = caseData?.personInCharge || null;
    this.services = caseData?.services && caseData?.services?.length > 0 ? caseData?.services.map((service) => new CaseService(service)) : [];
    this.attachment = caseData?.attachment && caseData?.attachment?.length > 0 ? caseData?.attachment.map((attachment) => new CaseAttachment(attachment)) : [];
    this.activityLog =
      caseData?.activityLog && caseData?.activityLog?.length > 0 ? caseData?.activityLog.map((activityLog) => new CaseActivityLog(activityLog)) : [];
    this.notifications = caseData?.notifications || [];
    this.smsNotify = caseData?.smsNotify || null;
    this.billing = caseData?.billing || null;
    this.distance = caseData?.distance || 0;
    this.driverDistance = caseData?.driverDistance || 0;
    this.campaign = caseData?.campaign || null;
    this.policeStation = caseData?.policeStation || null;
    this.locationRemark = caseData?.locationRemark || null;
    this.destination = caseData?.destination || null;
    this.isTrackingMapShow = caseData?.isTrackingMapShow || false;
    this.users = caseData?.users || null;
    this.debugErrorCount = caseData?.debugErrorCount || 0;
    this.carCondition = new CaseCarCondition(caseData?.carCondition);
    this.createdAt = caseData?.createdAt || null;
    this.additionalAddons = caseData?.additionalAddons || [];
    this.phoneNo = caseData?.phoneNo || null;
    this.altPhoneNo = caseData?.altPhoneNo || [];
    this.issue = caseData?.issue || undefined;
    this.accidentDate = caseData.accidentDate || null;
    this.rating = caseData.rating || null;
    this.automationLog = caseData.automationLog || null;
    this.supportingDocument = caseData.supportingDocument || [];
    this.photoReuploadReason = caseData.photoReuploadReason || null;
  }

  get serviceType() {
    if (this.services.length > 0) {
      return this.services[0].service;
    }

    if (this.description) {
      return this.description;
    }

    return null;
  }

  get personInChargeInitials() {
    let initials = [];

    if (this.personInCharge) {
      initials = this.personInCharge.split(' ').map((i) => i.charAt(0));

      if (initials.length > 2) {
        return initials.slice(0, 2).toString().replace(',', '').toLocaleUpperCase();
      }

      return initials.toString().replace(',', '').toLocaleUpperCase();
    }

    return null;
  }
}
